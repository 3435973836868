import axios, { AxiosRequestConfig } from 'axios'
import { getAccessToken } from '../utils/utils'

export const baseURL = window.sessionStorage.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL

export const api = axios.create({
  baseURL,
})

const excludeList = ['auth']

function onExcludeAuth(config: AxiosRequestConfig) {
  return !excludeList.some((excludeValue) => config?.url?.includes(excludeValue))
}

api.interceptors.request.use(
  function (config) {
    if (config?.headers) {
      config.headers['Authorization'] = `Bearer ${getAccessToken()}`
    }
    return config
  },
  // @ts-ignore
  null,
  { synchronous: true, runWhen: onExcludeAuth }
)

function onFormData(config: AxiosRequestConfig) {
  return config.method === 'post' && config.data instanceof FormData
}

api.interceptors.request.use(
  function (config) {
    if (config?.headers) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    return config
  },
  // @ts-ignore
  null,
  { synchronous: true, runWhen: onFormData }
)
