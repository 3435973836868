import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { TextField, TextFieldProps, Typography, Autocomplete } from '@mui/material'

type FieldInputValue = {
  city: string
  formatted?: string
  lat: number | null
  lng: number | null
  reason?: string
}

type GoogleAutocompleteFieldProps = TextFieldProps & {
  name: string
  label?: string
  customLabel?: string
  getOptionLabel?: (option: { description: string }) => string
  getFieldValue?: (value: FieldInputValue) => string
}

export default function GoogleAutocompleteField({
  name,
  label = '',
  customLabel = '',
  getOptionLabel = (option) => option?.description || '',
  getFieldValue = (value) => value?.city || '',
  placeholder = '',
  fullWidth,
}: GoogleAutocompleteFieldProps) {
  const [field, meta, helpers] = useField<FieldInputValue>({ name })

  const error = meta.touched && typeof meta.error === 'object' && getFieldValue(meta.error)

  const { t } = useTranslation()

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: window.sessionStorage.REACT_APP_GOOGLE || process.env.REACT_APP_GOOGLE,
      debounce: 300,
    })

  const setValue = useCallback(
    (placeDetails: any) => {
      const placeLocation = placeDetails.geometry.location
      helpers.setValue({
        city: placeDetails?.name ?? '',
        formatted: placeDetails?.formatted_address ?? '',
        lat: placeLocation.lat(),
        lng: placeLocation.lng(),
      })
    },
    [helpers]
  )

  const onChange = useCallback(
    (event: any, value: any) => {
      if (placePredictions.length && value) {
        placesService?.getDetails(
          {
            placeId: value.place_id,
          },
          setValue
        )
      }
    },
    [placePredictions, placesService, setValue]
  )

  const onReset = useCallback(() => {
    helpers.setValue({
      city: '',
      formatted: '',
      lat: null,
      lng: null,
    })
  }, [helpers])

  return (
    <>
      {customLabel && (
        <Typography variant="h5" mb={1.5}>
          {customLabel}
        </Typography>
      )}
      {/*@ts-ignore*/}
      <Autocomplete
        id={field.name}
        {...field}
        // disableClearable={true}
        options={placePredictions}
        getOptionLabel={getOptionLabel}
        inputValue={getFieldValue(field.value)}
        onChange={onChange}
        onBlur={(event) => {
          if (!meta.touched) {
            field.onBlur(event)
          }
          // This logic to help a user select a new value from the dropdown to get lat, lng
          // For example, if the user decides to input manually, (not to select from dropdown) after a blur it will reset value,
          // so the user have to select from the dropdown to get lat, lng
          if (field.value?.reason === 'input') {
            onReset()
          }
        }}
        onInputChange={(event, value, reason) => {
          if (reason === 'input') {
            getPlacePredictions({ input: value })
            helpers.setValue({
              ...field.value,
              city: value,
              reason: 'input',
            })
          } else if (reason === 'clear') {
            onReset()
          }
        }}
        loading={isPlacePredictionsLoading}
        fullWidth={fullWidth}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            error={!!error}
            helperText={error ? t(`${error}`) : ' '}
          />
        )}
      />
    </>
  )
}
