import { useMutation } from 'react-query'
import { api } from '../api'
import { companyKeys, UpdateCompany } from './queries'

export interface UpdateApplicant {
  user_id: number | string
  accepted_interview?: boolean
}

export interface UpdateApplicantStatus {
  inbox: UpdateApplicant[]
  review: UpdateApplicant[]
  interview: UpdateApplicant[]
  contract: UpdateApplicant[]
  reject: UpdateApplicant[]
}

const sendInvite = async (payload: { consultantId: number, assignmentId: number }) => {
  return api.post(`/assignments/${payload.assignmentId}/invite-consultant/${payload.consultantId}`);
};

const updateCompany = async (body: UpdateCompany) => {
  const response = await api.patch('company', body)
  return response.data
}
export const useUpdateCompanyMutation = () => useMutation(companyKeys.company, updateCompany)
export const useSendInviteToAssignmentMutation = () => useMutation(companyKeys.sendInviteToAssignment(), sendInvite)
