import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { companyKeys, useAssignmentOptionsForInviteQuery } from 'api/company/queries';
import { useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next';
import { useSendInviteToAssignmentMutation } from 'api/company/mutations';

type InviteToAssignmentDialogProps = {
  open: boolean;
  onClose: () => void;
  consultantId: number;
}

const InviteToAssignmentDialog: React.FC<InviteToAssignmentDialogProps> = ({
  open,
  onClose,
  consultantId,
}) => {
  const { t } = useTranslation();
  const [selectedAssignment, setSelectedAssignment] = useState<number | null>(null);
  const queryClient = useQueryClient();

  const { data: assignmentOptions = [], isLoading } = useAssignmentOptionsForInviteQuery(consultantId)
  
  const sortedAssignments = [...assignmentOptions].sort((a, b) => Number(b.selectable) - Number(a.selectable));

  const inviteMutation = useSendInviteToAssignmentMutation();

  const handleConfirm = async () => {
    if (selectedAssignment) {
      try {
        await inviteMutation.mutateAsync(
          { consultantId, assignmentId: selectedAssignment },
          {
            onSuccess: () => {
              const keys = companyKeys.assignmentOptionsForInvite(consultantId);
              queryClient.refetchQueries(keys, {
                active: true,
                exact: true
              })
              enqueueSnackbar(t('Common.InvitationSent'), { variant: 'success' });
              onClose();
            },
            onSettled: () => {
              setSelectedAssignment(null);
            }
          }
        );
      } catch (error) {}
    } else {
      enqueueSnackbar(t('Common.SelectAssignment'), { variant: 'warning' });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth 
      PaperProps={{ sx: { maxWidth: { xs: 440, sm: 520 }, width: '100%', m: 2 } }}
    >
      <DialogContent sx={{ px: { xs: 1, sm: 6 }, py: 4 }}>
        <IconButton
          onClick={onClose}
          sx={{
            p: 0,
            top: 16,
            right: 16,
            position: 'absolute',
          }}
        >
          <CloseIcon id="close" sx={{ fontSize: '18px' }} />
        </IconButton>
        <Stack direction="column" spacing={4} alignItems="center">
          <Typography variant="h3" align="center" mb={2}>
            {t('Common.InviteToAssignment')}
          </Typography>
          <Divider sx={{ width: '100%' }} />
          <Typography
            variant="body"
            align="center"
            color="text.secondary"
            display="inline-block"
            mb={4}
          >
            {t('Common.WhenYouSend')}
          </Typography>
          {isLoading ? (
            <Typography>{t('Common.LoadingAssignments')}</Typography>
          ) : (
            <Select
              fullWidth
              value={selectedAssignment || ''}
              onChange={(e) => setSelectedAssignment(Number(e.target.value))}
              displayEmpty
            >
              <MenuItem value="" disabled>
                {t('Common.SelectAnAssignment')}
              </MenuItem>
              {sortedAssignments.map(({ label, value, selectable = true }) => (
                <MenuItem key={value} value={value} disabled={!selectable}>
                  <Stack spacing={-0.5}>
                    <Typography>{label}</Typography>
                    {!selectable && (
                      <Typography variant="caption" color="error" display="block">
                        {t("Common.AmongApplicants")}
                      </Typography>
                    )}
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          )}
          <Box sx={{ maxWidth: '108px', mx: 'auto' }}>
            <Button
              id="confirm"
              disabled={inviteMutation.isLoading}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              onClick={handleConfirm}
            >
              {t(inviteMutation.isLoading ? 'general.confirming' : 'general.confirm')}
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default InviteToAssignmentDialog;
