import { QueryFunctionContext, useQuery } from 'react-query'
import { pageLimitCount } from '../../utils/utils'
import { api } from '../api'
import { TabType } from '../types'
import { useUserQuery } from '../user/queries'
import { Assignments, AssignmentsResponse } from '../assignments/queries'
import { Options } from 'components/Filter'

export type Company = {
  fk_user_id: number
  company_id: number
  company_name: string
  org_number: string
  address_line: string
  address_line_2?: string
  city: string
  zip_code: string
  country: string
  profile_photo: string | null
  cover_picture: string | null
  company_description: string
  name: string
  email: string
  password: string
}

export type UpdateCompany = Partial<Company>

type CompanyListings = {
  assignments: Assignments
  total: number
  otherTotal: number
  paginationParams: {
    offset: number
    limit: number
  }
}

export const companyKeys = {
  company: ['company'] as const,
  companyByUserId: (id: string | number = '') => ['by-user', id] as const,
  companyAssignmentsByCompanyId: ({
    id,
    page,
    limit,
  }: {
    id: string | number
    page: number
    limit: number
  }) => [id, 'assignments', page, limit] as const,
  listings: ({
    id,
    tab = 'active',
    page = 1,
    limit,
  }: {
    id: string | number
    tab: TabType
    page: number
    limit: number
  }) => [id, 'listings', tab, page, limit] as const,
  assignmentOptions: () => ['assignment-options'] as const,
  assignmentOptionsForInvite: (id: string | number = '') => ['assignment-options-for-invite', id] as const,
  sendInviteToAssignment: () => ['send-invite-to-assignment'] as const,
}

export const fetchCompanyByUserId = async ({
  queryKey,
}: QueryFunctionContext): Promise<Company | undefined> => {
  const userId = queryKey[1]

  if (userId) {
    const response = await api.get(`company/by-user/${userId}`)
    return response.data
  }

  return Promise.resolve(undefined)
}

const fetchCompanyAssignmentsByCompanyId = async ({
  queryKey,
}: QueryFunctionContext): Promise<AssignmentsResponse | undefined> => {
  // eslint-disable-next-line
  const [companyId, _, page, limit] = queryKey as readonly [number | string, string, number, number]

  if (companyId) {
    const response = await api.get(`company/${companyId}/assignments?offset=${page}&limit=${limit}`)
    return response.data
  }
  return Promise.resolve(undefined)
}

const fetchCompanyListings = async ({
  queryKey,
}: QueryFunctionContext): Promise<CompanyListings | undefined> => {
  // eslint-disable-next-line
  const [userId, _, tab, page, limit] = queryKey as readonly [
    number | string,
    string,
    TabType,
    number,
    number
  ]

  if (userId && tab && page >= 0 && limit) {
    const response = await api.get(
      `company/${userId}/listings/${tab}?offset=${page}&limit=${limit}`
    )

    return response.data
  }

  return Promise.resolve(undefined)
}

const fetchAssignmentOptions = async (): Promise<Options> => {
  const response = await api.get(`company/assignment-options`)
  return response.data
}

const fetchAssignmentOptionsForInvite = async ({
  queryKey,
}: QueryFunctionContext): Promise<Options> => {
  const consultantId = queryKey[1]
  const response = await api.get(`company/assignment-options/invite-consultant/${consultantId}`)
  return response.data
}

export const useCompanyByUserIdQuery = (userId: string | number = '') => {
  const user = useUserQuery()
  return useQuery(
    companyKeys.companyByUserId(userId || user?.data?.user_id),
    fetchCompanyByUserId,
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useCompanyAssignmentsByCompanyIdQuery = (
  id: string | number = '',
  page = 1,
  limit = pageLimitCount
) =>
  useQuery(
    companyKeys.companyAssignmentsByCompanyId({ id, page, limit }),
    fetchCompanyAssignmentsByCompanyId,
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  )

export const useCompanyListingsQuery = (
  tab: TabType = 'active',
  page = 1,
  limit = pageLimitCount,
  userId?: string | number
) => {
  const user = useUserQuery()
  const id = userId || user?.data?.user_id || ''
  return useQuery(companyKeys.listings({ id, tab, page, limit }), fetchCompanyListings, {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  })
}

export const useAssignmentOptionsQuery = () => {
  return useQuery(companyKeys.assignmentOptions(), fetchAssignmentOptions, {
    refetchOnWindowFocus: false
  })
}

export const useAssignmentOptionsForInviteQuery = (consultantId: number) => {
  return useQuery(companyKeys.assignmentOptionsForInvite(consultantId), fetchAssignmentOptionsForInvite, {
    refetchOnWindowFocus: false
  })
}
